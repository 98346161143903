<template>
<v-row 
  class="fill-height"
  no-gutters>
  <v-col 
    align="center"                
    justify="center"
    cols="6">
      <div style="width: 560px; height: 800px">
        <div  id="divVHSS"></div>
      </div>
  </v-col>
  <v-col 
    align="start"
    justify="start"
    align-self="end"
    cols="6">
      <v-card class="py-10 px-5 ma-5 text-h4" style="border: thick solid #6b1530 !important;">        
        Greetings! I'm a talking avatar court self-help assistant and I'm thrilled to be updating my knowledge!  <br>
        <br>
        <span class="font-weight-medium"> Once I'm up and running, I'll be here to assist all Court visitors with a range of services. </span> <br>
        - Need help finding your way into the courthouse or accessing basic court information? I've got you covered. <br>
        - Have questions about court proceedings or need to obtain court forms? I'm here to help with that too! <br>
        - And that's just the beginning - there's much more I can do! <br>
        <br>
        I can't wait to say hello and lend a hand in any way I can. <br>
        <br>        
        Thanks for stopping by!


      </v-card>
      <!-- <v-card class="py-10 px-5 ma-5 text-h4" style="border: thick solid #6b1530 !important;">
        Greetings! I'm a talking avatar court self-help assistant and I'm thrilled to be updating my knowledge!  <br>
        <br>
        <span class="font-weight-medium"> Once I'm up and running, I'll be here to assist all Court visitors with a range of services. </span> <br>
        - Need help finding your way into the courthouse or accessing basic court information? I've got you covered. <br>
        - Have questions about court proceedings or need to obtain court forms? I'm here to help with that too! <br>
        - And that's just the beginning - there's much more I can do! <br>
        <br>
        I can't wait to say hello and lend a hand in any way I can. <br>
        <br>        
        Thanks for stopping by!
      </v-card>     -->
  </v-col>
</v-row>
</template>

<script>
  

  export default {
    name: 'Home',

    components: {

    },
    methods:{
      loadAvatar() {
          var sc = document.createElement('script');
          sc.type = "text/javascript";
          sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=7295865&js=1";
          sc.onload = () => {
            this.callEmebedFunction();
          }
          document.head.appendChild(sc);
        },
        callEmebedFunction() {
          setTimeout(() => {
            if (typeof AC_VHost_Embed == 'function') {
              var script = document.createElement('script');
              script.type = "text/javascript";
              script.innerHTML =
                "AC_VHost_Embed(7295865,960,960,'',1,0,2712925,0,1,1,'FdNVehuFsGFPKNZoEJqtZ6DvsjLQvRVH',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";                            
              document.getElementById('divVHSS').appendChild(script);                          
            }
          }, 0)
        },

    },
    mounted(){
      this.loadAvatar()
    }
  }
</script>

<style>

#divVHSS-inner{
  top: 120px !important;
  left: -500px !important;
}
._html5Player{
  left: -40px !important;
}

</style>
